import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="text-6xl font-bold font-sans">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="text-xl text-green-400" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div
      className="max-w-screen-md m-auto flex flex-col pt-10 pb-5 px-6"
      data-is-root-path={isRootPath}
    >
      <header className="mb-12">{header}</header>
      <main>{children}</main>
    </div>
  )
}

export default Layout
